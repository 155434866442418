import React, { useState } from 'react';

import { PhoneOutlined, EnvironmentOutlined, MailOutlined } from '@ant-design/icons';

import { Row, Col } from 'antd';

import Cluar from '../../../Cluar';

import config from '../../../config.json';

import './index.less';

export default ({ title, content }) => {
    return (
      <section className="map">
        <h1>{title}</h1>
        <Row>
          <Col span={24}>
            <div className="text">
            <Row>
              <Col>
                <div dangerouslySetInnerHTML={{__html: content}}></div>
              </Col>
            </Row>
            </div>
            <div className="text">
            <Row>
              <Col>
                <br />
              </Col>
            </Row>
              <Row>
                <Col><EnvironmentOutlined /></Col>
                <Col>
                  <p>
                  &nbsp;Rua Jos&eacute; Joaquim Marques n.&ordm; 110<br />
                  2870-348 Montijo,<br /> &nbsp;Portugal
                  </p>
                </Col>
              </Row>
              <Row>
                <Col><PhoneOutlined /></Col>
                <Col><p>&nbsp;(+351) 912 524 751</p></Col>
              </Row>
              <Row>
                <Col><MailOutlined /></Col>
                <Col>
                  <p>
                    <a href="mailto:admin@sitana.pt">&nbsp; admin@sitana.pt</a><br />
                    <a href="mailto:commercial@sitana.pt">&nbsp; commercial@sitana.pt</a><br />
                    <a href="mailto:finance@sitana.pt">&nbsp; finance@sitana.pt</a>
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>
    );
};
