import React from 'react';
import { Col } from 'antd';

import './index.less';

export default ({ section, type, image, image_title, image_alt, title, content, link }) => {

  let layout = null;
  if (type === 'containers') {
    layout = (
      <li className="listing_item_containers">
        <div className={`item__containers`}>
          <h1>{title}</h1>
          <img src={`/images/${section}/${image}`} alt={ image_alt } title={ image_title }/>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </li>
    );
  }
  else if (type === 'service-list') {
    layout = (
      <Col xs={12} lg={6}>
        <a href={link} alt={title}><span>{title}</span><div className="filter"></div><div className="bg" style={{ backgroundImage: `url('/images/${section}/${image}')` }} ></div></a>
      </Col>
    );
  } else if (type === 'pallets') {
    layout = (
      <Col xs={24} sm={24} md={24} lg={12}>
        <img src={`/images/${section}/${image}`} alt={ image_alt } title={ image_title }/>
        <div className="listing_item_pallets" dangerouslySetInnerHTML={{ __html: content }}></div>
      </Col>
    );
  } else if (type === 'home-list') {
    layout = (
      <Col xl={8} lg={8} md={12} sm={24} xs={24}>
        <img src={`/images/${section}/${image}`} alt={ image_alt } title={ image_title } style={{ height: '256px', width: '256px' }} />
        <span>{title}</span>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </Col>
    )
  } else if (type === 'plan-list') {
    layout = (
      <Col xl={12} lg={12} md={24} sm={24} xs={24}>
        <div>
          <span>{title}</span>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </Col>
    )
  } else if (type === 'plan-comparison-list') {
    layout = (
      <div>
        {<div dangerouslySetInnerHTML={{ __html: content }}></div>}
        <p style={{color:'rgba(255, 255, 255, 0.85)', fontSize: '0.7em', fontWeight: '300'}}>*máximo de 25 SMS por mês.</p>
      </div>
    )
  } else {
    layout = (
      <li className="listing_item" style={{ backgroundImage: `url(/images/${section}/${image})` }}>
        <div className={`item__${type}`}>
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </li>
    );
  }
  return (
    layout
  );
}
