import React from 'react';

import Actions from '../Actions';

import Cluar from '../../Cluar';

import { Row, Col } from 'antd';

import './index.less';

export default ({ section, type, image, image_title, image_alt, title, content, position, actions }) => {
  let backgroundPositionX = position.x != "" ? position.x : "50%";
  let backgroundPositionY = position.y != "" ? position.y : "50%";

  return (
    <section className="banner">
      <div className={`banner__${type}`} style={{
        backgroundImage: `url(/images/${section}/${image})`,
        backgroundPositionX: backgroundPositionX,
        backgroundPositionY: backgroundPositionY
      }}>
        {(type === 'home') ?
          <Row>
            <Col xl={16} lg={16} md={24} sm={24} xs={24}>
              <div>
                <h1 data-sal="slide-down" data-sal-duration="2000" data-sal-easing="ease-out-cubic">{title}</h1>
                <div data-sal="fade" data-sal-duration="2000" data-sal-easing="ease-out-cubic" dangerouslySetInnerHTML={{ __html: content }}></div>
              </div>
              <Actions {... { section, type, actions }} />
            </Col>
            {/* <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Row>
                <img src="/images/product.png"/>
              </Row>
            </Col> */}
          </Row>
          :
          <Row>
            <Col xl={11} lg={(type === 'home') ? (24) : (12)} sm={(type === 'default-sub-banner') ? (24) : (type === 'home') ? (24) : (18)} xs={(type === 'default-sub-banner') ? (24) : (type === 'home') ? (24) : (18)}>
              <div>
                <h1 data-sal="slide-down" data-sal-duration="2000" data-sal-easing="ease-out-cubic">{title}</h1>
                <div data-sal="fade" data-sal-duration="2000" data-sal-easing="ease-out-cubic" dangerouslySetInnerHTML={{ __html: content }}></div>
              </div>
            </Col>
            <Col xl={24} lg={(type === 'home') ? (24) : (6)} sm={(type === 'default-sub-banner') ? (24) : (type === 'home') ? (24) : (6)} xs={(type === 'default-sub-banner') ? (24) : (type === 'home') ? (24) : (6)}>
              <Actions {... { section, type, actions }} />
            </Col>
          </Row>
        }
        <div className="banner__sub-banner">
          {Cluar.plainDictionary('text-sub-banner')}
        </div>
        <div className="banner__darken-bg"></div>
      </div>
    </section >
  );
}
