import React, { useState, useEffect } from 'react';

import Burger from '@animated-burgers/burger-slip';
import '@animated-burgers/burger-slip/dist/styles.css';

import classNames from 'classnames';

import { Layout, Menu, Button, Row, Col } from 'antd';

import { GlobalOutlined, PhoneOutlined, EnvironmentOutlined, MailOutlined } from '@ant-design/icons';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import Cluar from './Cluar.js';

import Builder from './Builder.js';

import Cookies from './components/Cookies';

import './App.less';

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

export default () => {

  const storageLocale = window.localStorage.getItem('locale');
  if (storageLocale == null) {
    window.localStorage.setItem('locale', Cluar.currentLanguage().locale);
  } else {
    Cluar.changeLanguage(storageLocale);
  }

  const [burgerMenu, setBurgerMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState('main');
  const [locale, setLocale] = useState(Cluar.currentLanguage().locale);

  const handleMenuClick = (selectMenu) => {
    setBurgerMenu(false);
    if (selectMenu) {
      setActiveMenu(selectMenu);
    }
    window.scrollTo(0, 0);
  };

  const menuLanguages = [];
  const menu = [];
  const subMenuKeys = [];
  const routes = [];
  for (const language of Cluar.languages()) {
    if (!Cluar.pages()[language.code]) {
      continue;
    }
    if (language.code !== Cluar.currentLanguage().code) {
      menuLanguages.push(
        <Menu.Item key={language.code} onClick={() => {
          Cluar.changeLanguage(language.locale);
          window.localStorage.setItem('locale', Cluar.currentLanguage().locale);
          window.location.href = `/${language.locale}/`;
        }}>{language.description}</Menu.Item>
      );
    }

    const buildMenu = (page) => {
      if (page.menu && language.code === Cluar.currentLanguage().code) {
        const key = `${page.link}`;
        if (Cluar.pages()[language.code].find((p) => p.menu && p.parent == page.link)) {
          subMenuKeys.push(key);
          return (
            <SubMenu key={key} title={
              <Link to={`/${Cluar.currentLanguage().locale}${page.link}`} onClick={() => handleMenuClick(key)}>
                {page.title}
              </Link>
            }>
              { Cluar.pages()[language.code].filter((p) => p.menu && p.parent == page.link).map((p) => buildMenu(p))}
            </SubMenu>
          );
        } else {
          return (
            <Menu.Item key={key}>
              <Link to={`/${Cluar.currentLanguage().locale}${page.link}`} onClick={() => handleMenuClick(key)}>
                {page.title}
              </Link>
            </Menu.Item>
          );
        }
      }
      return null;
    };

    const subroutes = [];
    for (const page of Cluar.pages()[language.code]) {
      if (page.menu && page.parent == "" && language.code === Cluar.currentLanguage().code) {
        menu.push(
          buildMenu(page)
        );
      }
      subroutes.push(
        <Route key={`/${language.locale}${page.link}`} path={`/${language.locale}${page.link}`} exact render={(propRouter) => {
          return <Builder page={page} />;
        }} />
      );
    }
    routes.push(
      <Route key={`/${language.locale}/`} path={`/${language.locale}/`}>
        {subroutes}
      </Route>
    );
  }

  menu.push(<Button className="actions__item-reserved" href="https://client.tarsiu.com">Entrar</Button>)

  useEffect(() => {
  }, []);

  return (
    <Router>
      <div className="page">
        <Layout>
          <Header className={classNames({ 'header-burger-open': burgerMenu })}>
            <div className="logo">
              <Link to={`/${Cluar.currentLanguage().locale}/`} onClick={() => handleMenuClick('/')}>
                <img src="/images/logo.svg" />
              </Link>
            </div>
            <div className={
              classNames({
                'menu': true
              })
            }>
              <Menu
                theme="light"
                mode="horizontal"
                defaultSelectedKeys={[activeMenu]}
                selectedKeys={[activeMenu]}>
                {menu}
              </Menu>
            </div>
            <div className={
              classNames({
                'menu': true,
                'menu-burger': true,
                'menu-burger-open': burgerMenu
              })
            }>
              <Menu
                theme="light"
                mode="inline"
                defaultSelectedKeys={[activeMenu]}
                selectedKeys={[activeMenu]}
                openKeys={subMenuKeys}>
                {menu}
              </Menu>
            </div>
            <div className="menu-burger-button">
              <Burger isOpen={burgerMenu} onClick={() => { setBurgerMenu(!burgerMenu); }} />
            </div>
            <Menu
              theme="light"
              className="menu-languages"
              mode={'horizontal'}
              defaultSelectedKeys={[activeMenu]}
              selectedKeys={[activeMenu]}>
              <SubMenu icon={<GlobalOutlined />} title={Cluar.currentLanguage().code}>
                {menuLanguages}
              </SubMenu>
            </Menu>
          </Header>
          <Content>
            <Switch>
              <Route path="/" exact render={(propRouter) => {
                return <Redirect to={`/${Cluar.currentLanguage().locale}/`} />;
              }} />
              {routes}
            </Switch>
          </Content>
          <Footer>
            <h1>O seu guardião, sempre alerta.</h1>
            <div className={"line"} />
            <p className="links-bar">
              <a href={`/${Cluar.currentLanguage().locale}${Cluar.currentLanguage().locale == 'pt' ? '/politica-de-privacidade' : Cluar.currentLanguage().locale == 'en' ? '/privacy-policy' : null}`}>{Cluar.plainDictionary('privacy-policy')}</a>
                &nbsp; &middot; &nbsp;
              <a href={`/${Cluar.currentLanguage().locale}${Cluar.currentLanguage().locale == 'pt' ? '/termos-e-condicoes' : Cluar.currentLanguage().locale == 'en' ? '/terms-and-conditions' : null}`}>{Cluar.plainDictionary('terms-and-conditions')}</a>
            </p>
            <p className="copyright">&copy; {new Date().getFullYear()} - Criado pela <a href="https://sitana.pt">Sitana</a> utilizando a plataforma <a href="https://netuno.org">Netuno</a>.</p>
            <p className="links-bar">
              <p className="cellphone" ><PhoneOutlined /> (+351) 912 524 751</p>
            </p>
            <p className="copyright"><EnvironmentOutlined /> Rua José Joaquim Marques n.º 110 <br /> 2870-348 Montijo</p>
          </Footer>
          <Cookies />
        </Layout>
      </div>
    </Router >
  );
}
