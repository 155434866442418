import React from 'react';

import { Row } from 'antd';
import { Collapse } from 'antd';


import Item from './Item';

import './index.less';

export default ({ section, type, image, image_title, image_alt, title, content, items }) => {
  const { Panel } = Collapse;
  const children = [];
  for (const item of items) {
    children.push(<Item {...{ type, ...item }} />);
  }

  let listLayout = (
    <div>
      <h1>{title}</h1>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
      <ul className={`listing__${type}`}>
        {children}
      </ul>
    </div>
  );

  if (type === 'home-list') {
    listLayout = (
      <Row className={`listing__${type}`} justify="start">
        { children}
      </Row>
    );
  }
  else if (type === 'faq-list') {
    const panels = [];
    for (const [index, item] of items.entries()) {
      if (item.content != null || item.content != '') {
        panels.push(<Panel header={item.title} key={index}> <p></p>{item.content.replace( /(<([^>]+)>)/ig, '')} </Panel>);
      }
    }
    listLayout = (
      <Collapse bordered={false} className={`listing__${type}`} accordion>
        { panels}
      </Collapse>
    );
  }
  else if (type === 'plan-list') {
    listLayout = (
      <>
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
        <Row className={`listing__${type}`}>
          {children}
        </Row>
      </>
    );
  } else if (type === 'plan-comparison-list') {
    listLayout = (
      <div className={`listing__${type}`}>
        <h2>{title}</h2>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
        {children}
      </div>
    );
  }

  return (
    <section className="listing">
      { listLayout}
    </section>
  );
}
